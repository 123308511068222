import React, { useRef, useEffect } from 'react'
import { Password } from 'primereact/password'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { AutoComplete } from 'primereact/autocomplete'
import { locale, addLocale } from 'primereact/api'

import { Icon, Text, Flex } from '.'

// Estrae lo stile dal componente e ritorna quelli necessari al contenitore
const extractStyle = (props) => {
  const { width, height, marginTop, marginBottom, marginLeft, marginRight, gridArea, ...otherStyles } = props.style
  props.style = otherStyles
  return { width, height, marginTop, marginBottom, marginLeft, marginRight, gridArea }
}

const monthNavigatorTemplate = (e) =>
  <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />

const yearNavigatorTemplate = (e) =>
  <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className='p-ml-2' style={{ lineHeight: 1 }} />

export const Input = ({
  setRef, setValue, useState, onChange, onEnter, yearNavigator, monthNavigator, yearRange,
  autofocus, password, time, date, label, multiline, dropdown, inputAutoComplete,
  autoComplete, error, autocomplete, dropdownAutoComplete, square, ...props
}) => {
  const inputRef = useRef(null)
  setRef && setRef(inputRef)
  props.ref = inputRef
  useEffect(() => {
    autofocus && setTimeout(() => {
      if (!inputRef || !inputRef.current) return false
      inputRef.current.element && inputRef.current.element.focus()
      inputRef.current.inputEl && inputRef.current.inputEl.focus()
    }, 500)
  }, [autofocus])

  if (onChange && !props.id) throw new Error('Missing ID for OnChange')

  props.value === undefined && (props.value = '')
  useState && useState.length === 2 && (props.value = useState[0])
  useState && useState.length === 2 && (props.onChange = (e) => useState[1](e.target.value))
  setValue && (props.onChange = (e) => setValue(e.target.value))
  onChange && (props.onChange = (e) => onChange({ [e.target.id]: e.target.value }))
  onEnter && (props.onKeyDown = (key) => key.keyCode === 13 && onEnter())
  useState && onChange && (props.onChange = (e) => {
    useState[1](e.target.value)
    onChange(e.target.value)
  })

  props.autoComplete = autoComplete || 'off'

  props.style = {
    border: 'none',
    borderRadius: 20,
    paddingLeft: 12,
    width: '100%',
    ...(props.id ? { gridArea: props.id } : {}),
    ...props.style
  }

  error && (props.className = 'invalid-input')

  /* if (password) {
    props.autoComplete = 'current-password'
    props.placeholder = 'Password'
    props.feedback = false
    return <Password {...props} />
  } */

  if (square) {
    const containerStyle = extractStyle(props)
    props.style.width = props.style.textWidth || '50%'
    props.style.flexGrow = 1
    props.style.marginLeft = 5
    props.style.backgroundColor = '#f8f9fa'
    props.style.borderRadius = '0px 7px 7px 0px'
    return (
      <Flex row js style={{ backgroundColor: '#acca95', paddingLeft: 12, borderRadius: '7px 7px 7px 7px', ...containerStyle }}>
        <Text value={label} color='#FFFF' />
        <InputText {...props} />
      </Flex>
    )
  }

  if (date) {
    const containerStyle = extractStyle(props)
    const newYearRange = yearRange || '2010:2030'
    props.showIcon = props.showIcon || false
    // props.locale = dateLocale.it
    props.monthNavigatorTemplate = monthNavigatorTemplate
    props.yearNavigatorTemplate = yearNavigatorTemplate
    props.dateFormat = 'dd/mm/yy'
    props.readOnlyInput = true
    props.inputStyle = {
      paddingLeft: 12,
      borderRadius: 20,
      border: 'none'
    }

    const { className, ...otherProps } = props

    return (
      <Flex row js style={{ backgroundColor: 'white', paddingLeft: 12, borderRadius: props?.style?.borderRadius || 20, ...containerStyle }} {...className ? { className } : {}}>
        {label ? <Text value={label} color='rgb(120,120,120)' style={{ whiteSpace: 'nowrap' }} /> : null}
        <Calendar {...otherProps} />
      </Flex>
    )
  }

  if (time) {
    return (
      <div
        style={{
          marginLeft: -10,
          position: 'relative',
          ...props.style,
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20
        }}
      >
        <Calendar
          inputStyle={{
            paddingLeft: label ? label.length * 8 + 12 : 12,
            borderRadius: 20
          }}
          showIcon
          value={props?.value !== 'Invalid Date' ? props?.value : ''}
          onChange={(e) => {
            onChange({ [e.target.id]: e.target.value })
          }}
          timeOnly
          showTime
          hourFormat='24'
          className={`${error ? 'invalid-input' : ''}`}
          {...props}
        />
        {label && (
          <Text style={{ position: 'absolute', left: 20, top: 6 }} color='rgb(120, 120, 120)' value={label} />
        )}
      </div>
    )
  }

  if (multiline) {
    props.autoResize === undefined && (props.autoResize = true)
    props.style.height = '100%'
    props.style.padding = 15
    return <InputTextarea {...props} />
  }

  if (dropdown) {
    if (label) {
      const containerStyle = extractStyle(props)
      props.style.width = props.style.textWidth || '50%'
      props.style.flexGrow = 1
      props.style.marginLeft = 5
      return (
        <Flex row js style={{ backgroundColor: 'white', paddingLeft: 12, borderRadius: 20, ...containerStyle }}>
          <Text value={label} color='rgb(120,120,120)' />
          <Dropdown {...props} />
        </Flex>
      )
    }
    return <Dropdown {...props} />
  }
  if (inputAutoComplete) {
    const containerStyle = extractStyle(props)

    const { style, ...extraProps } = props

    return (
      <Flex fw row js style={{ backgroundColor: 'white', paddingLeft: 12, borderRadius: 20, ...containerStyle }}>
        <Text value={label} color='rgb(120,120,120)' />
        <AutoComplete
          inputStyle={{ width: '100%', borderRadius: 20 }}
          style={{ width: '100%' }}
          onSelect={({ value }) => onChange({ [props.id]: value[props.id] })}
          {...extraProps}

        // {/* inputStyle={{
        //  ...styles.inputStyle,
        //  width: '100%',
        //  borderRadius: 20
        // ...style */

        // dropdown={dropdownAutoComplete}
        />
      </Flex>
    )
  }

  if (label) {
    const containerStyle = extractStyle(props)
    props.style.width = props.style.textWidth || '50%'
    props.style.flexGrow = 1
    props.style.marginLeft = 5
    return (
      <Flex row js style={{ backgroundColor: 'white', paddingLeft: 12, borderRadius: 20, ...containerStyle }}>
        <Text value={label} color='rgb(120,120,120)' />
        <InputText {...props} />
      </Flex>
    )
  }

  return <InputText {...props} />
}

addLocale('it', {
  firstDayOfWeek: 1,
  dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
  dayNamesMin: ['D', 'L', 'Ma', 'Me', 'G', 'V', 'S'],
  monthNames: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ],
  monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
})
locale('it')

export const InputOld = ({
  id,
  value,
  classNameStyle,
  /* onChange accept just one value */
  dropdownAutoComplete,
  onChange,
  onBlur,
  dropdown,
  options,
  keyfilter,
  password,
  style,
  icon,
  placeholder,
  shadow,
  multiline,
  date,
  minDate,
  maxDate,
  label,
  disabled,
  gridbyid,
  onEnter,
  error,
  autocomplete,
  suggestions,
  completeMethod,
  itemTemplate,
  field,
  size,
  minLength,
  time,
  insertLabel,
  extraStyle,
  onSelect,
  monthNavigator = false,
  yearNavigator = false,
  yearRange,
  noAutocomplete
}) => {
  const boxShadow = shadow ? styles.boxShadow : ''
  gridbyid && (styles.inputStyle.gridArea = id)

  const inputBase = (extraStyle, error) => (
    <InputText
      style={{
        ...styles.inputStyle,
        width: insertLabel ? '80%' : '100%',
        boxShadow,
        ...style,
        ...extraStyle,
        borderTopLeftRadius: icon ? 0 : 20,
        borderBottomLeftRadius: icon ? 0 : 20
      }}
      {...noAutocomplete ? { autoComplete: 'off' } : {}}
      placeholder={placeholder}
      id={id}
      value={value}
      disabled={disabled}
      keyfilter={keyfilter || null}
      onChange={(e) => onChange({ [e.target.id]: e.target.value })}
      onBlur={onBlur}
      className={`${error ? 'invalid-input' : ''}`}
    />
  )

  const renderInput = (extraStyle, error) => {
    if (insertLabel) {
      // se label crea la label precendete all'inputText altrimenti l'inputText base e basta
      return (
        <div
          style={{
            ...style,
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'white',
            paddingLeft: 12,
            alignItems: 'center',
            border: 'none',
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20
          }}
          className={`${error ? 'invalid-input' : ''}`}
        >
          <Text value={label} color='rgb(120,120,120)' />
          {inputBase(extraStyle)}
        </div>
      )
    } else {
      return inputBase(extraStyle, error)
    }
  }

  const renderPassword = () => (
    <Password
      onKeyDown={(key) => key.keyCode === 13 && onEnter && onEnter()}
      feedback={false}
      placeholder={placeholder}
      sstyle={{
        ...styles.inputStyle,
        boxShadow,
        ...style,
        backgroundColor: 'red'
      }}
      inputStyle={{
        ...styles.inputStyle,
        boxShadow,
        ...style
      }}
      value={value}
      onChange={(e) => onChange({ password: e.target.value })}
    />
  )

  const renderWithIcon = (error) => (
    <div className='p-inputgroup' style={{ boxShadow }}>
      <span
        className={` ${error ? 'invalid-input' : ''} p-inputgroup-addon`}
        style={{
          backgroundColor: 'white',
          borderBottomLeftRadius: 20,
          borderTopLeftRadius: 20
        }}
      >
        <Icon name={icon} size={20} />
      </span>
      {renderInput({ width: '100%', boxShadow: '', paddingLeft: 5 })}
    </div>
  )

  const renderMultiline = (error) => (
    <InputTextarea
      autoResize
      placeholder={placeholder}
      style={{
        ...styles.inputStyle,
        boxShadow,
        ...style
      }}
      id={id}
      value={value}
      onChange={(e) => onChange({ [e.target.id]: e.target.value })}
      onBlur={onBlur}
      className={`${error ? 'invalid-input' : ''}`}
    />
  )

  const renderDatePicker = (error) => (
    <div
      style={{
        position: 'relative',
        ...style,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20
      }}
    >
      <Calendar
        inputStyle={{
          paddingLeft: label ? label.length * 8 + 12 : 12,
          borderRadius: 20,
          border: 'none'
        }}
        monthNavigator={monthNavigator}
        yearNavigator={yearNavigator}
        yearRange={yearRange || '2010:2030'}
        minDate={minDate || null}
        maxDate={maxDate || null}
        showIcon
        dateFormat='dd/mm/yy'
        id={id}
        value={value}
        onChange={(e) => onChange({ [e.target.id]: e.target.value })}
        placeholder={placeholder}
        className={`${error ? 'invalid-input' : ''}`}
      />
      {label && (
        <Text style={{ position: 'absolute', left: 12, top: 6 }} color='rgb(120,120,120)' value={label} />
      )}
    </div>
  )

  const renderTimePicker = (error) => (
    <div
      style={{
        position: 'relative',
        ...style,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20
      }}
    >
      <Calendar
        inputStyle={{
          paddingLeft: label ? label.length * 8 + 12 : 12,
          borderRadius: 20
        }}
        showIcon
        id={id}
        value={value !== 'Invalid Date' ? value : ''}
        onChange={(e) => {
          onChange({ [e.target.id]: e.target.value })
        }}
        placeholder={placeholder}
        timeOnly
        showTime
        hourFormat='24'
        className={`${error ? 'invalid-input' : ''}`}
      />
      {label && (
        <Text style={{ position: 'absolute', left: 12, top: 6 }} color='rgb(120, 120, 120)' value={label} />
      )}
    </div>
  )

  const renderDropdown = (error) => (
    <Dropdown
      id={id}
      value={value}
      placeholder={placeholder}
      style={{
        ...styles.inputStyle,
        width: '100%',
        paddingLeft: 6,
        borderRadius: 20,
        ...style
      }}
      onChange={(e) => onChange({ [e.target.id]: e.target.value })}
      disabled={disabled}
      options={options}
      className={`${error ? 'invalid-input' : ''}`}
    />
  )

  const inputAutoComplete = (error) => (
    <AutoComplete
      value={value}
      suggestions={suggestions} // i suggetimenti che il metodo complete filtra metodo che filtra i dati e andrà ad assegnare a suggestion i dati filtrati
      completeMethod={completeMethod}
      field={field}
      size={size}
      inputStyle={{
        ...styles.inputStyle,
        width: '100%',
        borderRadius: 20,
        ...style
      }}
      style={{
        width: '100%'
      }}
      placeholder={placeholder}
      minLength={minLength || 1}
      itemTemplate={itemTemplate}
      onSelect={onSelect}
      onChange={(e) => onChange(e.target.value)}
      {...(error ? { className: 'invalid-input' } : {})}
      dropdown={dropdownAutoComplete}
    />
  )

  const renderAutoComplete = (error) => {
    if (insertLabel) {
      // se     label crea la label precendete all'inputText altrimenti l'inputText base e basta
      return (
        <div
          style={{
            ...style,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'white',
            paddingLeft: 12,
            alignItems: 'center',
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20
          }}
          className={`${error ? 'invalid-input' : ''}`}
        >
          <Text value={label} color='rgb(120,120,120)' />
          {inputAutoComplete()}
        </div>
      )
    } else {
      return inputAutoComplete(error)
    }
  }
  if (password) return renderPassword(error)
  if (multiline) return renderMultiline(error)
  if (icon) return renderWithIcon(error)
  if (date) return renderDatePicker(error)
  if (time) return renderTimePicker(error)
  if (dropdown) return renderDropdown(error)
  if (autocomplete) return renderAutoComplete(error)
  return renderInput(extraStyle, error)
}

const styles = {
  inputStyle: {
    paddingLeft: 12,
    border: 'none',
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,

    width: '100%',
    height: '100%'
  },
  boxShadow: {
    boxShadow:
      '1px 1px 3px 0 rgba(0, 0, 0, 0.2), 1px 1px 1px 0 rgba(0, 0, 0, 0.14), 2px 1px 1px -1px rgba(0, 0, 0, 0.12)',
    borderRadius: 5
  }
}
